@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap");

html {
  font-family: "Roboto", sans-serif;
  color: white;
}

body {
  background-color: #0d4a56;
}

h1 {
  text-transform: uppercase;
}
h2,
h3 {
  text-transform: uppercase;
  padding-bottom: 1em;
  padding-top: 2em;
  font-weight: 700;
}

p {
  padding-bottom: 1em;
}

.splash {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  /* background-color: red; */
  justify-content: center;
  align-items: center;
}

.scroll-indicator {
  position: absolute;
  bottom: 24px;
  width: 48px;
  border-bottom: none !important;
}

.scroll-indicator img {
  animation: fadeInOut 3s infinite ease-in-out;
}

.logo {
  width: 65%;
  padding-bottom: 2%;
}

.hero {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 24px;
  max-width: 1024px;
  width: 100%;
  /* background-color: red; */
}

.heading {
  font-size: min(5.5vw, 60px);
}

.bg {
  object-fit: cover;
  object-position: center center;
  width: 100vw;
  min-height: 100vh;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.content {
  max-width: 1024px;
  /* background-color: rebeccapurple; */
  margin: 3em auto 3em auto;
  /* padding: 12px; */
  display: flex;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
  padding: 12px;
  /* align-items: center; */
  /* background-color: blue; */
}

.legal {
  text-align: center;
  margin-bottom: 3em;
  margin-top: auto;
}

a {
  color: white;
  text-decoration: none;
  transition: all 0.15s ease-in;
  border-bottom: 1px solid transparent;
}

a:hover {
  border-bottom: 1px solid white;
}

.row {
  display: flex;
  /* background-color: blue; */
}

.column {
  padding: 24px;
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
}

.button {
  border: 1px solid white;
  padding: 12px;
  margin-top: 12px;
  align-self: flex-start;
  margin-bottom: 12px;
}

@media only screen and (max-width: 660px) {
  .row {
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
  }
}

@keyframes fadeInOut {
  /* flame pulses */
  0% {
    opacity: 0.7;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-12px);
  }
  100% {
    opacity: 0.7;
    transform: translateY(0);
  }
}
